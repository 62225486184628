import * as React from "react"
import Layout from "../components/layout"

const IndexPage = () => {
  return (
    <Layout landingPage="/" />
  )
}

export default IndexPage
